import { Container, Row, Col, Image } from "react-bootstrap";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";

import Ingrid from "../../imgs/alumni/ingrid.png";
import Inigo from "../../imgs/alumni/inigo.jpg";
import Leo from "../../imgs/alumni/leo.jpg";
//Media import

import accenture from "../../imgs/logos_/accenture.png";
import gGames from "../../imgs/logos_/g_logo.png";
import gfi from "../../imgs/logos_/GFI-logo.png";
import google from "../../imgs/logos_/Google-Logo.png";
import oesia from "../../imgs/logos_/grupo-oesia-logo.png";
import irontec from "../../imgs/logos_/irontec_logo.png";
import jamenet from "../../imgs/logos_/jamenet-logo.png";
import serikat from "../../imgs/logos_/logo-base.png";
import enutt from "../../imgs/logos_/logo-enutt-comunicacion.png";
import ibermatica from "../../imgs/logos_/logo-ib.png";
import reappciona from "../../imgs/logos_/reappciona-logo.png";
import roxall from "../../imgs/logos_/roxall-logo.png";
import deloitte from "../../imgs/logos_/deloitte.svg";
import eroski from "../../imgs/logos_/eroski-logo.svg";
import fleeet from "../../imgs/logos_/fleeet.svg";
import pixtin from "../../imgs/logos_/pixtin.svg";
import salesforce from "../../imgs/logos_/salesforce-logo.svg";
import workoholics from "../../imgs/logos_/workoholics.svg";
import irisbond from "../../imgs/logos_/irisbond.png";
import accexible from "../../imgs/logos_/accexible.png";
import plainConcepts from "../../imgs/logos_/plain-concepts-logo@2x.png";

import { AiOutlineCode } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { RiRoadMapLine } from "react-icons/ri";

import { LanguageContext } from "../../context/Language";
import ReactHtmlParser from "react-html-parser";
import Precurso from "../Precurso/Precurso";
import BulletPoints from "../BulletPoints/BulletPoints";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

export default function Landing() {
  const { dictionary } = useContext(LanguageContext);

  function scrollToForm() {
    document.querySelector(".banner-row").scrollIntoView({
      behavior: "smooth",
    });
  }

  const images = [
    { img: accenture, link: "https://www.accenture.com/" },
    { img: gGames, link: "https://g.games/" },
    { img: gfi, link: "https://gfi.world/es-es/" },
    { img: google, link: "https://www.google.es" },
    { img: oesia, link: "https://grupooesia.com/" },
    { img: irontec, link: "https://www.irontec.com/" },
    { img: jamenet, link: "http://jamenet.eus/" },
    { img: serikat, link: "https://www.serikat.es/" },
    { img: enutt, link: "https://www.enutt.net/" },
    { img: ibermatica, link: "https://ibermatica.com/" },
    { img: reappciona, link: "https://www.reappciona.net/" },
    { img: roxall, link: "https://www.roxall.es/" },
    { img: fleeet, link: "https://www.myfleeet.com/" },
    { img: salesforce, link: "https://www.salesforce.com/es/" },
    { img: workoholics, link: "https://www.workoholics.es/" },
    { img: deloitte, link: "https://www2.deloitte.com/es/es.html" },
    { img: eroski, link: "https://www.eroski.es/" },
    { img: pixtin, link: "https://pixtin.es/" },
    { img: irisbond, link: "https://www.irisbond.com/" },
    { img: accexible, link: "https://accexible.com/" },
    { img: plainConcepts, link: "https://www.plainconcepts.com/" },
  ];
  const classes = useStyles();

  return (
    <div id="home">
      <Container className="" style={{ marginTop: "100px", bottom: "0" }}>
        <Row className="banner-row">
          <Card className="banner-card">
            <RiRoadMapLine size={50} />
            <br />
            <h3>CURRICULUM</h3>
            <h5
              className="textoDymoLight text-center"
              style={{ backgroundColor: "rgba(255,255,255,0)" }}
            >
              Nuestro currículum incluye día a día todo el material práctico y
              teórico. Basado en la metodología Learn by doing.
            </h5>
          </Card>
          <Card className="banner-card">
            <FaBuilding size={50} />
            <br />
            <h3>FORMACIÓN INSTRUCTORES</h3>
            <h5
              className="textoDymoLight text-center"
              style={{ backgroundColor: "rgba(255,255,255,0)" }}
            >
              Incluimos la formación y soporte a los instructores y te
              acompañamos en todo el proceso.
            </h5>
          </Card>
          <Card className="banner-card">
            <AiOutlineCode size={50} />
            <br />
            <h3>ATRACCIÓN DE ESTUDIANTES</h3>
            <h5
              className="textoDymoLight text-center"
              style={{ backgroundColor: "rgba(255,255,255,0)" }}
            >
              Te apoyamos en la generación de engagement con alumnos potenciales
              del bootcamp.
            </h5>
          </Card>
        </Row>
      </Container>
      <Container>
        <Container className="mt-5">
          <Row className="d-flex justify-content-center">
            <h2 style={{ marginTop: "0" }}>{dictionary.landing.titulo1}</h2>
          </Row>
          <Row className="d-flex justify-content-center text-justify">
            <Col>
              <p className="explanation">{dictionary.landing.explicacion11}</p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center text-justify">
            <Col>
              <p className="explanation">{dictionary.landing.explicacion12}</p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center text-justify">
            <Col>
              <p className="explanation">{dictionary.landing.explicacion13}</p>
            </Col>
          </Row>
        </Container>
        <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
        <Container style={{ marginBottom: "3vh" }}>
          <Row className="text-center">
            <Col>
              <h2>{dictionary.landing.titulo2}</h2>
              <p className="explanation">{dictionary.landing.explicacion2}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Container style={{ marginTop: 30 }} className="text-justify">
                    <p className="explanation">
                      {ReactHtmlParser(dictionary.landing.card1.explicacion)}
                    </p>
                  </Container>
                  <Row>
                    <Col>
                      <ul style={{ paddingLeft: "30%" }}>
                        <li>{dictionary.landing.card1.ul.li1}</li>
                        <li>{dictionary.landing.card1.ul.li2}</li>
                        <li>{dictionary.landing.card1.ul.li3}</li>
                        <li>{dictionary.landing.card1.ul.li4}</li>
                        <li>{dictionary.landing.card1.ul.li5}</li>
                        <li>{dictionary.landing.card1.ul.li6}</li>
                        <li>{dictionary.landing.card1.ul.li7}</li>
                      </ul>
                    </Col>
                  </Row>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </Container>
        <Precurso />
        <Container className="text-center">
          <Container style={{ marginTop: 60 }}>
            <h2 style={{ textAlign: "center" }}>
              {dictionary.landing.testimonios.titulo}
            </h2>
            <Container>
              <Row style={{ marginTop: 20 }}>
                <Col className="d-flex justify-content-center flex-lg-row-reverse ">
                  <Image
                    className="foto-alumno"
                    src={Leo}
                    alt=""
                    style={{ maxWidth: 300, maxHeight: 300 }}
                    roundedCircle
                  />
                </Col>
                <Col
                  style={{ marginTop: 20 }}
                  className="d-flex flex-row align-items-center"
                >
                  <div>
                    <h4>{dictionary.landing.testimonios.leo.titulo}</h4>
                    <br />

                    <p style={{ marginTop: -20 }}>
                      <span
                        style={{ fontSize: "1em" }}
                        className="mdi mdi-format-quote-open"
                      />
                      <i className="explanation">
                        {dictionary.landing.testimonios.leo.explicacion1}
                      </i>
                      <span
                        style={{ fontSize: "1em" }}
                        className="mdi mdi-format-quote-close"
                      />
                    </p>
                    <p className="explanation">
                      {dictionary.landing.testimonios.leo.explicacion2}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col className="d-flex justify-content-center flex-lg-row  order-md-2">
                  <Image
                    className="foto-alumno "
                    src={Ingrid}
                    alt=""
                    style={{ maxWidth: 300, maxHeight: 300 }}
                    roundedCircle
                  />
                </Col>
                <Col
                  style={{ marginTop: 20 }}
                  className="d-flex flex-row   align-items-center "
                >
                  <div>
                    <h4>{dictionary.landing.testimonios.ingrid.titulo}</h4>
                    <br />
                    <p style={{ marginTop: -20 }}>
                      <span
                        style={{ fontSize: "1em" }}
                        className="mdi mdi-format-quote-open"
                      />
                      <i className="explanation">
                        {dictionary.landing.testimonios.ingrid.explicacion1}
                      </i>
                      <span
                        style={{ fontSize: "1em" }}
                        className="mdi mdi-format-quote-close"
                      />
                    </p>
                    <p className="explanation">
                      {dictionary.landing.testimonios.ingrid.explicacion2}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col className="d-flex flex-lg-row-reverse justify-content-center">
                  <Image
                    className="foto-alumno"
                    src={Inigo}
                    alt=""
                    style={{ maxWidth: 300, maxHeight: 300 }}
                    roundedCircle
                  />
                </Col>
                <Col
                  style={{ marginTop: 20 }}
                  className="d-flex flex-row align-items-center"
                >
                  <div>
                    <h4>{dictionary.landing.testimonios.inigo.titulo}</h4>
                    <br />
                    <p style={{ marginTop: -20 }}>
                      <span
                        style={{ fontSize: "1em" }}
                        className="mdi mdi-format-quote-open"
                      />
                      <i className="explanation">
                        {dictionary.landing.testimonios.inigo.explicacion1}
                      </i>

                      <span
                        style={{ fontSize: "1em" }}
                        className="mdi mdi-format-quote-close"
                      />
                    </p>
                    <p className="explanation">
                      {dictionary.landing.testimonios.inigo.explicacion2}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
            <button id="reviews-button" onClick={scrollToForm}>
              <p className="explanation" style={{ margin: 0 }}>
                {dictionary.landing.testimonios.exito}
              </p>
            </button>
          </Container>
        </Container>
        <hr
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            marginTop: "2%",
            marginBottom: "2%",
          }}
        />
        <Container>
          <h2 style={{ textAlign: "center" }}>
            Beneficios de estudiar con nosotros
          </h2>
          <BulletPoints />
        </Container>
        <hr
          style={{ marginLeft: "10%", marginRight: "10%", marginTop: "2%" }}
        />
        <Container style={{ marginTop: 30 }}>
          <Row className="text-center">
            <Col>
              <h2>{dictionary.landing.empresas}</h2>
            </Col>
          </Row>
          <Row className="d-flex flex-wrap justify-content-between">
            {images.map((logo, i) => {
              return (
                <Container
                  key={i}
                  className="d-inline-flex align-items-center justify-content-center"
                  style={{ height: 80, width: 150, marginTop: 40 }}
                >
                  <a
                    className="d-inline-flex align-items-center justify-content-center"
                    style={{ width: 150 }}
                    href={logo.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={logo.img} alt="" fluid />
                  </a>
                </Container>
              );
            })}
          </Row>
        </Container>
        <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
        <Container>
          <h2 style={{ textAlign: "center" }}>
            ¿Quieres saber más sobre nuestros cursos intensivos de programación?
          </h2>
          <h4 style={{ textAlign: "center" }}>
            Déjanos tus datos, ¡y estaremos encantados de explicarte!
          </h4>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button id="reviews-button" onClick={scrollToForm}>
              <p className="explanation" style={{ margin: 0 }}>
                Ir al formulario
              </p>
            </button>
          </div>
        </Container>
      </Container>
    </div>
  );
}
