import { useLocation } from "react-router-dom";
import { Barra } from "../../components";
import Banner from "./Banner";
import BannerDos from "./BannerDos";

const Header = () => {
  const location = useLocation();

  function scroll() {
    window.scrollTo({
      top: 0,
      left: 0,
      /* behavior: "smooth", */
    });
  }
  scroll();

  // if (location.pathname === "/onboarding") {
  //   return (
  //     <>
  //       <Barra id="top" />
  //       <Banner
  //         clase="onboarding"
  //         t1="Especialistas en talento digital y onboarding"
  //         t2="Las mejores empresas ya trabajan con nosotros"
  //         t3="El mejor talento tech forma parte de nuestra comunidad"
  //         location={location.pathname}
  //       />
  //     </>
  //   );
  // } else if (location.pathname === "/encuentra-desarrolladores") {
  //   return (
  //     <>
  //       <Barra id="top" />
  //       <Banner
  //         clase="developers"
  //         t1="Especialistas en talento digital y onboarding"
  //         t2="Las mejores empresas ya trabajan con nosotros"
  //         t3="El mejor talento tech forma parte de nuestra comunidad"
  //         location={location.pathname}
  //       />
  //     </>
  //   );
  // }
  // else if(location.pathname === "/agente-digitalizador"){
  //   return (
  //     <>
  //       <Barra id="top" />
  //       <Banner
  //         clase="agente-digitalizador"
  //         t1="Especialistas en talento digital y onboarding"
  //         t2="Las mejores empresas ya trabajan con nosotros"
  //         t3="El mejor talento tech forma parte de nuestra comunidad"
  //         location={location.pathname}
  //       />
  //     </>
  //   )
  // }
  // else {
  return (
    <>
      {/* <Barra id="top" />
        <Banner
          clase="humo"
          t1="Especialistas en talento digital y onboarding"
          t2="Las mejores empresas ya trabajan con nosotros"
          t3="El mejor talento tech forma parte de nuestra comunidad"
          location={location.pathname}
        /> */}
      <Barra id="top" />
      <BannerDos
        clase="humo"
        t1="¿Quieres poner en marcha un bootcamp de programación?"
        t2="Te ayudamos a poner en marcha el bootcamp Full Stack. Plug & Play: te apoyamos en todo el proceso"
        // t3="Plug & Play: te apoyamos en todo el proceso"
      />
    </>
  );
  // }
};

export default Header;
