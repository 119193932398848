import { Link } from "react-router-dom";
import { Nav, Navbar, Image, Button } from "react-bootstrap";
//Media import

import logo from "../../imgs/branding/logotipo.svg";

export default function Barra() {
  return (
    <Navbar
      style={{ backgroundColor: "rgba(250,250,250,0.9" }}
      collapseOnSelect
      sticky="top"
      expand="lg"
    >
      <Navbar.Brand as={Link} to="/">
        <Image
          style={{ width: 200, margin: 0 }}
          src={logo}
          alt="Code2Jobs logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
          {/* <a id="btc-pres" href="https://cursos.code4jobs.com" target="_blank">
            Bootcamp Full Stack
          </a>
          <Nav.Link as={Link} to="/encuentra-desarrolladores">
            Encuentra desarrolladores
          </Nav.Link>
          <Nav.Link as={Link} to="/onboarding">
            Onboarding
          </Nav.Link> */}
          {/* <Nav.Link as={Link} to="/agente-digitalizador">
            Agente Digitalizador
          </Nav.Link> */}
          <Link to="/contacto">
            <Button
              size="sm"
              variant="outline-danger"
              style={{ marginTop: "3px" }}
            >
              Contacto
            </Button>
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
