import { Container } from "react-bootstrap";

export default function Terminos() {

  
  return (
    <Container>
      <h1>Política de privacidad y protección de datos</h1>
      <p>
        La utilización de determinadas solicitudes dirigidas a Code4jobs S.L.
        requiere de la previa cumplimentación de formularios incluidos en la
        web. Los datos de naturaleza personal facilitados por medio de dichos
        formularios serán tratados de forma absolutamente confidencial con la
        finalidad de gestionar los servicios ofrecidos por Code4jobs S.L.
      </p>

      <p>
        La información que el Usuario facilite a Code4jobs S.L. a través de
        estos formularios deberá ser exacta y veraz. El usuario garantiza la
        autenticidad de todos los datos que comunique y mantendrá actualizada la
        información a fin de que corresponda, en todo momento, con la situación
        real del usuario. En el supuesto de manifestaciones inexactas,
        incompletas o falsas comunicadas por el usuario, éste será el único
        responsable por los perjuicios que debido a ello se pudieran causar a
        Code4jobs S.L. o a terceros.
      </p>

      <h2>Protección de Datos Usuarios y Clientes</h2>

      <p>
        En cumplimiento de lo establecido en la Ley Orgánica 15/1999, de 13 de
        diciembre, de Protección de Datos de Carácter Personal le comunicamos
        que los datos que usted nos facilite, serán tratados e incorporados a un
        fichero propiedad de la Asociación Española de la Economía Digital, a
        los efectos de poder proporcionarle el servicio solicitado, así como
        mandarle información sobre nuestras actividades.
      </p>

      <p>
        Puede ejercer los derechos de acceso, rectificación, cancelación y
        oposición a sus datos de carácter personal mediante correo electrónico
        dirigido a <a href="mailto://hola@code4jobs.com">hola@code4jobs.com</a>
      </p>

      <p>
        En cumplimiento del artículo 5 de la Ley Orgánica 15/1999, de 13 de
        diciembre, de Protección de Datos de Carácter Personal, por el que se
        regula el derecho de información en la recogida de datos, le informamos
        que los datos personales facilitados a través de www.code4jobs.com serán
        incorporados a un Fichero de Datos de Carácter Personal, titularidad de
        Code4jobs S.L. como Responsable del Fichero, cuya finalidad es la de
        gestionar los mismos para mantener las relaciones contractuales,
        comerciales y profesionales que nos unen a usted, así como para el envío
        de comunicaciones postales, telemáticas, o por otros medios, con ocasión
        de acontecimientos puntuales, o en ciertos periodos del año, que puedan
        ser de su interés. Si usted no desea el envío de esta tipología de
        información notifíquelo a través de 
        <a href="mailto://hola@code4jobs.com"> hola@code4jobs.com</a>
      </p>

      <p>
        Le informamos de que sus datos personales serán comunicados a los
        Organismos y Administraciones públicas que corresponda, a los
        profesionales directamente relacionados con la entidad para la correcta
        prestación del servicio y a las entidades bancarias con las que
        trabajamos.
      </p>

      <p>
        Puede ejercer sus derechos de acceso, rectificación, cancelación y
        oposición dirigiéndose a{" "}
        <a href="mailto://hola@code4jobs.com">hola@code4jobs.com</a> a la
        Atención del Responsable de la Ley Orgánica de Protección de Datos.
      </p>

      <p>
        BBF (BIlbao Berrikuntza Faktoria) <br />
        Paseo Uribitarte, 3, 48001 Bilbao, Biscay
      </p>
    </Container>
  );
}
