import { Row, Container } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";

import { AiOutlineCode } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { RiRoadMapLine } from "react-icons/ri";

import logo from "../../imgs/branding/logotipo.svg";
import { Button, TextField, Checkbox, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useState } from "react";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Banner({ clase, t1, t2, t3, t4, location }) {
  const classes = useStyles();

  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [puesto, setPuesto] = useState("");
  const [aceptacion, setAceptacion] = useState(false);

  function sendData(e) {
    e.preventDefault();

    let obj = {
      name: nombre,
      puesto: puesto,
      email: email,
    };

    let data = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch("https://p01--forms--rxbh62sm9p4x.code.run/dev", data)
      .then((res) => res.json())
      .then((res) => {
        window.location = "/confirmacion";
      });
  }

  return (
    <div
      className={`${clase} d-flex mt-5 align-items-center justify-content-center`}
    >
      <Container className="landing-container">
        <Row className="banner-row">
          <h2
            className="text-center"
            style={{
              color: "white",
              fontSize: "45px",
              fontWeight: "bold",
              marginBottom: "5vh",
            }}
          >
            {t1}
          </h2>
          <h4 className="text-center" style={{ color: "white" }}>
            {t2}
            {"\n"}
          </h4>
          <h4 className="text-center" style={{ color: "white" }}>
            {t3}
          </h4>
        </Row>
        <Row>
          <form
            className="companies-form-landing"
            noValidate
            action="https://docs.google.com/forms/d/e/1FAIpQLSfnQkrNewqvlU1mSq-HfFR8gD2K3BX64L3XNIh815yZ1hWwxQ/formResponse"
            method="POST"
            id="formulario"
          >
            <Grid className="align-items-center" container spacing={5}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="nombre"
                  name="entry.1924470624"
                  variant="outlined"
                  required
                  fullWidth
                  id="Nombre"
                  label="Nombre y Apellidos"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Tu Correo Electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="entry.1722148463"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="Puesto"
                  label="Página web de tu organización"
                  value={puesto}
                  onChange={(e) => setPuesto(e.target.value)}
                  name="entry.1234878829"
                  autoComplete="puesto"
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Checkbox
                  value="aceptacion"
                  name="entry.1371236259"
                  color="primary"
                  onChange={(e) => {
                    setAceptacion(!aceptacion);
                  }}
                />
                <span>Acepto los</span>
                <Link to="/politica-privacidad"> términos y condiciones.</Link>
              </Grid>
              <input
                hidden
                value="Reclutamiento"
                name="entry.483134198"
                color="primary"
              />
              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={!aceptacion}
                  onClick={(e) => sendData(e)}
                >
                  Quiero recibir información
                </Button>
              </Grid>
            </Grid>
          </form>
        </Row>
      </Container>
    </div>
  );
}
