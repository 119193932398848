import { Row, Col, Image, Navbar, Nav } from "react-bootstrap";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";

// Media import
import iso from "../../imgs/branding/logotipoCompleto.svg";

const Footer = () => {
  return (
    <>
      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
      <section>
        <Container style={{ marginTop: 60 }}>
          <Row>
            <Col md={4}>
              <Image
                style={{ maxWidth: 200, margin: 20 }}
                src={iso}
                alt="Code4Jobs Logo"
              />
            </Col>
            <Col className="d-flex flex-row align-items-center">
              <Navbar>
                <Nav>
                  <Nav.Link as={Link} to="/politica-privacidad">
                    Política de privacidad
                  </Nav.Link>
                  <p>&nbsp;&nbsp;&nbsp;</p>
                  <Nav.Link as={Link} to="/faq">
                    Preguntas frecuentes
                  </Nav.Link>
                </Nav>
              </Navbar>
            </Col>
            <Col
              className="d-flex flex-row-reverse align-items-center"
              style={{ textAlign: "end" }}
            >
              <a
                href="https://www.linkedin.com/company/code4jobs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="logorrss mdi mdi-linkedin" />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
