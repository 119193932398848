import { Row, Container, Col } from "react-bootstrap";
import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AiOutlineCode } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { RiRoadMapLine } from "react-icons/ri";

import logo from "../../imgs/branding/logotipo.svg";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Banner({ clase, t1, t2, t3, t4, location }) {
  const classes = useStyles();

  return (
    <>
      <div
        className={`${clase} d-flex mt-5 align-items-center justify-content-center`}
        >
          <Container className="landingImage d-flex">
            <img id="landingLogo" src={logo} alt="code4jobs" />
          </Container>
        <Container className="">
          <Row className="banner-row">
            <Card
              className="banner-card"
            >
              <RiRoadMapLine size={50} />
              <br />
              <h3
                className="textoDymoLight text-center"
                style={{ backgroundColor: "rgba(255,255,255,0)" }}
              >
                {t1}
              </h3>
            </Card>
            <Card
              className="banner-card"
            >
              <FaBuilding size={50} />
              <br />
              <h3
                className="textoDymoLight text-center"
                style={{ backgroundColor: "rgba(255,255,255,0)" }}
              >
                {t2}
              </h3>
            </Card>
            <Card
              className="banner-card"
            >
              <AiOutlineCode size={50} />
              <br />
              <h3
                className="textoDymoLight text-center"
                style={{ backgroundColor: "rgba(255,255,255,0)" }}
              >
                {t3}
              </h3>
            </Card>
          </Row>
        </Container>
      </div>
    </>
  );
}
