import { Row, Col } from "react-bootstrap";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  card: {
    width: "60%",
    textAlign: "left",
  },
}));

const Presencial = () => {
  const classes = useStyles();

  return (
    <>
      <Container className="d-flex justify-content-center">
        <Row className="d-flex justify-content-center">
          <Col sm={8}>
            <ul style={{ fontSize: "1.5em" }}>
              <li>12 semanas, de lunes a viernes de 9:00 a 17:00</li>
              <li>Precurso de 50 horas preparatorio</li>
              <li>Metodologías ágiles</li>
              <li>Preparación de CV y entrevistas de trabajo</li>
              <li>3 módulos, 3 proyectos personales</li>
              <li>Retos de empresa, trabajo en equipo</li>
              <li>
                Demo day en y ronda de entrevistas con empresas interesadas en
                contratar perfiles Full Stack
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="bloqueExplicacion">
        <h3>¿Cuáles son los requisitos de entrada?</h3>
        <p>
          No es necesario saber programar, puesto que te enseñaremos lo básico y
          tendrás un pre-curso para entender los fundamentos de la programación.
          Obviamente, si ya sabes programar en otros lenguajes también es
          perfecto, ya que, sobre todo al principio, tu ritmo de aprendizaje
          será mucho más rápido.
        </p>
        <p>
          Necesitarás algunos conocimientos básicos de informática para poder
          manejar un ordenador a nivel usuario y algo de inglés, ya que muchos
          conceptos de la programación son en inglés.
        </p>
        <p>
          Necesitarás ser mayor de edad (+ 18 años) para poder ser alumno de
          Code4Jobs.
        </p>
        <p>
          Por último, necesitarás tener un ordenador portátil para poder
          trabajar en él y sobre todo, ¡muchas ganas!
        </p>
      </Container>
      <Container className="bloqueExplicacion">
        <h3>¿Qué estudiaré exactamente?</h3>
        <p>
          El objetivo es equipar a los estudiantes con los conocimientos
          necesarios para desarrollar sistemas dinámicos de software basados en
          la web. Los estudiantes obtendrán una comprensión de los conceptos y
          la teoría de la informática básica necesarios para un desarrollador de
          software profesional, pero hacemos especial hincapié en la aplicación
          práctica, las técnicas y las herramientas
        </p>
        <p>
          Actualmente impartimos dos bootcamps diferentes basados en JavaScript
          o en .NET
        </p>
      </Container>
      <Container className="bloqueExplicacion">
        <h3>¿Cómo es el día a día del Bootcamp presencial?</h3>
        <p>
          El Bootcamp presencial es un curso Full Time. Los alumnos tendrán que
          asistir a clase de lunes a viernes de 9am a 5pm.
        </p>
        <ul>
          <li>09:00 a 14:00 - Nuevos conceptos y ejercicios</li>
          <li>14:00 a 15:00 - Comida</li>
          <li>15:00 a 17:00 - Ejercicios diarios </li>
        </ul>
        <p>
          A lo largo de las 12 semanas que dura el curso se realizarán diversos
          talleres con mentores para trabajar las habilidades blandas y la
          formación para el empleo.
        </p>
      </Container>
      <Container className="bloqueExplicacion">
        <h3>¿Cuánto cuesta el Bootcamp presencial?</h3>
        <p>
          El precio de BBK Bootcamp es de 3.300€. Según Coursereport, el precio
          medio de un coding bootcamp es de 11.874 dólares (10.315 euros).
        </p>
        <p>
          Nos hemos aliado con la Fundación BBK en bilbao porque creemos en el
          talento de las personas, por lo que ofrecemos becas para cubrir este
          pago. Según tus condiciones económicas, estas becas pueden llegar
          incluso hasta el 50-80% de la matrícula.*
        </p>
        <p>
          Ponte en contacto con nosotros en{" "}
          <a href="mailto://hola@code4jobs.com">Code4Jobs</a>
        </p>
      </Container>
      {/* Programa Completo --------------------------------------------------------------------------------------- */}
      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
      <Container className="bloqueExplicacion">
        <h3>Programa completo JavaScript</h3>
        <Row style={{ marginTop: 40 }}>
          <Col sm={3}>
            <h5 className="text-left">
              <span
                style={{ fontSize: "2em", fontWeight: 700, color: "#dd0000" }}
              >
                0
              </span>{" "}
              | Pre Curso - <br />
              Fundamentos
            </h5>
          </Col>
          <Col
            style={{
              borderLeft: "solid",
              borderColor: "#dd0000",
              paddingLeft: 30,
            }}
          >
            <p>
              Comenzaremos con un precurso que realizarás por tu cuenta en el
              que empezaremos instalando y familiarizándonos con todas las
              herramientas que usaremos a lo largo del curso, así como una
              introducción a <strong>HTML, CSS y JavaScript</strong> para
              empezar el curso sabiendo escribir código
            </p>
            <h6>Objetivos</h6>
            <ul>
              <li>
                Preparar nuestro IDE y familiairzarnos con las herramientas
              </li>
              <li>Introducción a HTML, CSS y JavaScript</li>
              <li>
                Realización de ejercicios simples que nos acostumbren a escribir
                código y su metodología
              </li>
              <li>Lograr un nivel inicial similar para todo el grupo</li>
            </ul>
            <h6>Duración</h6>
            <p>
              El precurso se compone de 5 partes que podrás organizarte a tu
              ritmo y tendrás desde el momento en el que te inscribes hasta que
              empecemos el curso (con un mínimo de 2 semanas) para realizarlo.
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col sm={3}>
            <h5 className="text-left">
              <span
                style={{ fontSize: "2em", fontWeight: 700, color: "#dd0000" }}
              >
                1
              </span>{" "}
              | Front End - <br />
              Html, Css, JavaScript
            </h5>
          </Col>
          <Col
            style={{
              borderLeft: "solid",
              borderColor: "#dd0000",
              paddingLeft: 30,
            }}
          >
            <p>
              Empezamos introduciendo herramientas básicas de programación y
              presentando los lenguajes que componen una página web.
              Entenderemos su estructura, sus partes, cómo trabajan en conjunto
              y cual es la finalidad de cada una de ellas. Al final del módulo,
              tendremos conocimientos avanzados de HTML, CSS y Javascript.
            </p>
            <h6>Objetivos</h6>
            <ul>
              <li>Manejo de la terminal</li>
              <li>
                Utilización de sistemas de control de versiones (Git y GitHub)
              </li>
              <li>
                Uso de lenguaje de marcado y de hojas de estilo (HTML y CSS)
              </li>
              <li>Introducción a la programación en JavaScript.</li>
              <li>
                Utilización de los 3 lenguajes en conjunto y sus funciones en el
                Front End
              </li>
            </ul>
            <h6>Duración</h6>
            <p>
              El primer módulo tiene una duración de 3 semanas en las que
              tendremos clase y ejercicios diarios, con una semana final para
              desarrollar y presentar el proyecto final de módulo
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col sm={3}>
            <h5 className="text-left">
              <span
                style={{ fontSize: "2em", fontWeight: 700, color: "#dd0000" }}
              >
                2
              </span>{" "}
              | Back End - <br />
              Node.JS, Expres.JS, MongoDB
            </h5>
          </Col>
          <Col
            style={{
              borderLeft: "solid",
              borderColor: "#dd0000",
              paddingLeft: 30,
            }}
          >
            <p>
              En este módulo profundizamos en el uso de Javascript y lo
              trasladaremos a su uso en el Back End, con Node JS y Express para
              montar nuestro propio servidor y nuestra propia API. <br />
              También veremos las bases de datos no relaciones y su manejo,
              luego las integraremos en nuestro servidor y aprendemos la forma
              de interaccionar con ellas.
            </p>
            <h6>Objetivos</h6>
            <ul>
              <li>Utilización de Node.js, sistema NPM, etc.</li>
              <li>Creación de un servidor mediante Express.js.</li>
              <li>Uso de formularios y recopilación de datos</li>
              <li>Utilización del protocolo RESTful y creación de una API</li>
              <li>Introducción a MongoDB</li>
              <li>Uso de MongoDB desde Express.js</li>
              <li>Creación de rutas en Express.js</li>
              <li>Funciones Middleware</li>
            </ul>
            <h6>Duración</h6>
            <p>
              Este segundo módulo volverá a durar 3 semanas en las que, como ya
              hicimos durante el primer módulo, tendremos clase a diario y una
              serie de ejercicios que nos ayudarán a afianzar los conocimientos
              adquiridos durante las mismas. <br />
              Además, volveremos a tener una semana final para desarrollar y
              presentar un proyecto final de módulo en el que aplicaremos todos
              los nuevos conocimientos absorbidos durante el segundo módulo.
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col sm={3}>
            <h5 className="text-left">
              <span
                style={{ fontSize: "2em", fontWeight: 700, color: "#dd0000" }}
              >
                3
              </span>{" "}
              | Stack MERN - <br />
              Librería REACT
            </h5>
          </Col>
          <Col
            style={{
              borderLeft: "solid",
              borderColor: "#dd0000",
              paddingLeft: 30,
            }}
          >
            <p>
              En este último módulo estudiaremos y utilizaremos la librería
              REACT. La utilizaremos para hacer un Front End dinámico y lo
              integraremos con nuestro Back-End poniendo en práctica todas las
              tecnologías aprendidas durante el curso (Stack MERN). <br />
              Al final del tercer módulo habremos recorrido todas las partes del
              stack MERN desarrollando habilidades prácticas tanto en el
              front-end cómo en el back-end y estaremos capacitados para hacer
              un dearrollo Full Stack
            </p>
            <h6>Objetivos</h6>
            <ul>
              <li>Introducción a React</li>
              <li>Uso de los Estados</li>
              <li>Sistema de Rutas para la creación de SPA</li>
              <li>Formularios en React</li>
              <li>Utilización de los parámetros de Ruta.</li>
              <li>Peticiones de datos a API</li>
              <li>Uso de los Componentes de clase</li>
              <li>Sistema Bootstrap y su implementación en React</li>
              <li>Uso del sistema de autentificación (Passport)</li>
            </ul>
            <h6>Duración</h6>
            <p>
              Este último módulo tendrá una duración de 4 semanas para
              profundizar adecuadamente en el conocimiento y el uso de REACT y
              cada una de sus herramientas, las ventajas que nos ofrece frente a
              lo visto anteriormente así como su integración en el stack MERN
              para la creación de un desarrollo Full Stack por completo. <br />
              Para finalizar, tendremos 4 semanas para desarrollar un proyecto
              final de bootcamp en el que aplicaremos todos los conocimientos,
              habilidades y tecnologías adquiridas que nos servirá como apertura
              de nuestro portfolio personal y que presentaremos a las empresas
              en el final del curso.
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col sm={3}>
            <h5 className="text-left">
              <span
                style={{ fontSize: "2em", fontWeight: 700, color: "#dd0000" }}
              >
                4
              </span>{" "}
              | Reto de Empresa - <br />
              Proyecto final del Bootcamp
            </h5>
          </Col>
          <Col
            style={{
              borderLeft: "solid",
              borderColor: "#dd0000",
              paddingLeft: 30,
            }}
          >
            <p>
              En las últimas tres semanas que componen el Bootcamp varias
              empresas presentan retos para que los alumnos desarrollen una
              solución a los mismos. Durante la realización de estos retos, los
              alumnos estarán en contacto directo con la empresa y con
              colaboradores del Bootcamp para poder llevar a cabo la tarea
              encomendada. El objetivo es el desarrollo de una aplicación real
              para dar solución a una situación real que cualquier empresa puede
              vivir en su día a día.
            </p>
          </Col>
        </Row>
      </Container>
      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
      <Container className="bloqueExplicacion">
        <h3>Programa completo .NET</h3>
        <Row style={{ marginTop: 40 }}>
          <Col sm={3}>
            <h5 className="text-left">
              <span
                style={{ fontSize: "2em", fontWeight: 700, color: "#dd0000" }}
              >
                0
              </span>{" "}
              | Pre Curso - <br />
              Fundamentos
            </h5>
          </Col>
          <Col
            style={{
              borderLeft: "solid",
              borderColor: "#dd0000",
              paddingLeft: 30,
            }}
          >
            <p>
              Comenzaremos con un precurso que realizarás por tu cuenta en el
              que empezaremos instalando y familiarizándonos con todas las
              herramientas que usaremos a lo largo del curso, así como una
              introducción a <strong>C#</strong> para empezar el curso sabiendo
              escribir código
            </p>
            <h6>Objetivos</h6>
            <ul>
              <li>
                Preparar nuestro IDE y familiairzarnos con las herramientas
              </li>
              <li>Introducción a C#</li>
              <li>
                Realización de ejercicios simples que nos acostumbren a escribir
                código y su metodología
              </li>
              <li>Lograr un nivel inicial similar para todo el grupo</li>
            </ul>
            <h6>Duración</h6>
            <p>
              El precurso se compone de 5 partes que podrás organizarte a tu
              ritmo y tendrás desde el momento en el que te inscribes hasta que
              empecemos el curso (con un mínimo de 2 semanas) para realizarlo.
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col sm={3}>
            <h5 className="text-left">
              <span
                style={{ fontSize: "2em", fontWeight: 700, color: "#dd0000" }}
              >
                1
              </span>{" "}
              | Back End - <br />
              C# y Microsoft SQL Server
            </h5>
          </Col>
          <Col
            style={{
              borderLeft: "solid",
              borderColor: "#dd0000",
              paddingLeft: 30,
            }}
          >
            <p>
              Empezamos desarrollando pequeños programas en consola, utilizando
              lo que hemos visto durante el precurso y añadiendo más
              herramientas y elementos. Veremos qué es la Programación Orientada
              a Objetos y cómo organizar nuestro código. Además, también
              estudiaremos SQL, su integración con nuestras aplicaciones en
              consola y el desarrollo de una base de datos desde cero.
            </p>
            <h6>Objetivos</h6>
            <ul>
              <li>Control de flujo del programa</li>
              <li>Estructuras de almacenamiento</li>
              <li>Métodos</li>
              <li>Base de datos</li>
              <li>POO (Programación Orientada a Objetos)</li>
              <li>Listas y Clases</li>
              <li>Contol de Excepciones</li>
            </ul>
            <h6>Duración</h6>
            <p>
              El primer módulo tiene una duración de 3 semanas en total en las
              que tendremos clase y ejercicios diarios, de las cuales la semana
              final estará destinada a desarrollar y presentar el proyecto final
              de módulo
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col sm={3}>
            <h5 className="text-left">
              <span
                style={{ fontSize: "2em", fontWeight: 700, color: "#dd0000" }}
              >
                2
              </span>{" "}
              | Front End - <br />
              Javascript, HTML y CSS
            </h5>
          </Col>
          <Col
            style={{
              borderLeft: "solid",
              borderColor: "#dd0000",
              paddingLeft: 30,
            }}
          >
            <p>
              En este módulo presentaremos los lenguajes que componen una página
              web. Entenderemos su estructura, sus partes, cómo trabajan en
              conjunto y cual es la finalidad de cada una de ellas. Al final del
              módulo, tendremos conocimientos avanzados de HTML, CSS y
              JavaScript.
            </p>
            <h6>Objetivos</h6>
            <ul>
              <li>Manejo de la terminal</li>
              <li>
                Utilización de sistemas de control de versiones (Git y GitHub)
              </li>
              <li>
                Uso de lenguaje de marcado y de hojas de estilo (HTML y CSS)
              </li>
              <li>Introducción a la programación en JavaScript</li>
              <li>
                Utilización de las 3 tecnologías en conjunto y sus funciones en
                el Front End
              </li>
            </ul>
            <h6>Duración</h6>
            <p>
              Este segundo módulo volverá a durar 3 semanas en las que, como ya
              hicimos durante el primer módulo, tendremos clase a diario y una
              serie de ejercicios que nos ayudarán a afianzar los conocimientos
              adquiridos durante las mismas. Además, volveremos a tener una
              semana final para desarrollar y presentar un proyecto final de
              módulo en el que aplicaremos todos los nuevos conocimientos
              adquiridos durante el segundo módulo.
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col sm={3}>
            <h5 className="text-left">
              <span
                style={{ fontSize: "2em", fontWeight: 700, color: "#dd0000" }}
              >
                3
              </span>{" "}
              | .Net Core - <br />
              Modelo-Vista-Controlador
            </h5>
          </Col>
          <Col
            style={{
              borderLeft: "solid",
              borderColor: "#dd0000",
              paddingLeft: 30,
            }}
          >
            <p>
              En el tercer módulo veremos cómo crear una aplicación web desde
              cero con el patrón de diseño MVC de .Net Core. Veremos también los
              diferentes elementos que la componen, así como la forma de alojar
              tanto nuestra base de datos como nuestra aplicación en Microsoft
              Azure
            </p>
            <h6>Objetivos</h6>
            <ul>
              <li>Fundamentos MVC (Modelo-Vista-Controlador)</li>
              <li>Creación de Controllers</li>
              <li>Creacion de Vistas</li>
              <li>Creación de Modelos</li>
              <li>Herencia</li>
              <li>Roles de usuario</li>
              <li>Indentity</li>
              <li>Microsoft Azure y sus servicios</li>
            </ul>
            <h6>Duración</h6>
            <p>
              Este último módulo también tendrá una duración de 3 semanas en las
              cuales se impartirá teoría mientras se realizarán ejercicios en
              clase. Durante la última semana se realizará el reto final de
              Módulo 3 poniendo en práctica todos los conocimientos y
              tecnologías aprendidas en este y todos los módulos anteriores.
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col sm={3}>
            <h5 className="text-left">
              <span
                style={{ fontSize: "2em", fontWeight: 700, color: "#dd0000" }}
              >
                4
              </span>{" "}
              | Reto de Empresa - <br />
              Proyecto final del Bootcamp
            </h5>
          </Col>
          <Col
            style={{
              borderLeft: "solid",
              borderColor: "#dd0000",
              paddingLeft: 30,
            }}
          >
            <p>
              En las últimas tres semanas que componen el Bootcamp varias
              empresas presentan retos para que los alumnos desarrollen una
              solución a los mismos. Durante la realización de estos retos, los
              alumnos estarán en contacto directo con la empresa y con
              colaboradores del Bootcamp para poder llevar a cabo la tarea
              encomendada. El objetivo es el desarrollo de una aplicación real
              para dar solución a una situación real que cualquier empresa puede
              vivir en su día a día.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Presencial;
