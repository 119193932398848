import { Container } from "react-bootstrap";

export default function FAQ() {
  /*  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  }); */
  return (
    <Container>
      <p>
        <strong>¿Cómo definirías un coding bootcamp?</strong>
      </p>
      <p>
        El Coding Bootcamp es un tipo de formación que surge por la demanda por
        parte de las empresas de obtener desarrolladores o programadores junior.
        Es un tipo de formación presencial intensiva en programación, con una
        duración de 3-4 meses, y del cual, los estudiantes salen con las
        habilidades necesarias para trabajar como desarrolladores de software
        junior. La formación técnica se complementa con el desarrollo de
        habilidades personales y orientación laboral.
      </p>
      <p>
        Este enfoque impulsado por la demanda justifica las altas tasas de
        inserción de estos programas, superiores al 75%, llegando en algunos
        casos al 100% de empleabilidad.
      </p>
      <p>
        Si se compara con cursos online en programación (ej. Coursera o Udemy),
        los bootcamps ofrecen valor añadido mediante la formación en habilidades
        blandas (networking, creatividad, resolución de problemas…) y
        orientación profesional, además de creando una red de compañeros y
        contactos en empresas del sector.
      </p>
      <p>
        La intensidad y la profundidad del Coding Bootcamp son clave para
        acercar a los alumnos al nivel requerido por parte de la industria.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>¿Es necesario saber programar para empezar el Bootcamp?</strong>
      </p>
      <p>¡No!</p>
      <p>&nbsp;</p>
      <p>
        <strong>
          ¿Cómo ayudamos a las empresas que quieren montar un bootcamp?
        </strong>
      </p>
      <ol>
        <li>
          Les ofrecemos todo el curriculum teórico y práctico hora a hora para
          las 12 semanas.
        </li>
        <li>Apoyamos en todo el proceso de admisiones de estudiantes.</li>
        <li>
          Diseñamos la estrategia de atracción de estudiantes conjuntamente.
        </li>
        <li>Formamos a los instructores del centro. </li>
        <li>Acompañamos al centro/empresa a lo largo de todo el bootcamp.</li>
        <li>
          Customizamos talleres para que sean únicos y formen parte del centro.
        </li>
        <li>
          Ayudamos en la búsqueda de métodos de pago para los estudiantes.{" "}
        </li>
      </ol>
      <p>
        Si hay algún aspecto que no encuentras en esta lista escríbenos porque
        probablemente también te daremos apoyo.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          ¿Cuál es la diferencia entre un coding bootcamp, una carrera
          universitaria y un módulo de formación profesional?
        </strong>
      </p>
      <p>
        El coding bootcamp tiene toda la materia concentrada en unas pocas
        semanas, lo suficiente para que puedas entrar al mundo laboral sabiendo
        todo lo necesario. Las carreras universitarias y los módulos también
        tienen asignaturas de programación, pero en estos casos están repartidas
        a lo largo del tiempo, y mezcladas con muchas otras materias. La
        concentración de un bootcamp hace que asimiles muy bien los conceptos y
        evoluciones muy rápido. Además, se desarrollan otras habilidades
        (comunicación, trabajo en equipo, liderazgo…) a la vez que aprendes a
        programar. ¡Y no hay exámenes!
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          ¿Participar en el coding bootcamp me asegura un trabajo?
        </strong>
      </p>
      <p>
        En todo el mundo, más del 75% de los que terminan un bootcamp encuentran
        un trabajo en menos de 3 meses. Hay algunos bootcamps en los que el 100%
        de los graduados encuentran trabajo. Nosotros con nuestra trayectoria
        ayudamos a estar en contacto con las empresas, a preparar el curriculum
        y el portafolio.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>¿Alguna otra pregunta?</strong>
      </p>
      <p>
        Estamos aquí para responderte:{" "}
        <a href="mailto:admisiones@code4jobs.com">admisiones@code4jobs.com</a>
      </p>
    </Container>
  );
}
