import { BrowserRouter, Route } from "react-router-dom";
import {
  Header,
  Landing,
  Presencial,
  Desarrolladores,
  OnBoarding,
  Terminos,
  FAQ,
  Footer,
} from "./components";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// Styles import
import "@mdi/font/css/materialdesignicons.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";

function App() {
  const theme = createMuiTheme({
    typography: {
      fontFamily: "Montserrat",
    },
    palette: {
      primary: {
        light: "#9a0000",
        main: "#dd0000",
        dark: "#e33333",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <Route exact path="/">
          <Landing />
        </Route>
        {/* <Route exact path="/curso-presencial">
          <Presencial />
        </Route>
        <Route exact path="/encuentra-desarrolladores">
          <Desarrolladores />
        </Route>
        <Route exact path="/onboarding">
          <OnBoarding />
        </Route> */}
        <Route exact path="/politica-privacidad">
          <Terminos />
        </Route>
        <Route exact path="/faq">
          <FAQ />
        </Route>
        {/* <Route exact path="/agente-digitalizador">
          <AgenteDigitalizador />
        </Route> */}
        {/* <Route exact path="/contacto">
          <Contacto />
        </Route> */}

        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
