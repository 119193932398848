import EngineeringIcon from "@mui/icons-material/Engineering";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ComputerIcon from "@mui/icons-material/Computer";

export default function BulletPoints() {
  return (
    <div id="card-list">
      <div className="card-bullet">
        <EngineeringIcon
          sx={{ fontSize: 50, color: "#dd0000", marginBottom: "2vh" }}
        />
        <h4 style={{ color: "black", textAlign: "center" }}>
          + del 80% de nuestros alumnos encuentra trabajo 4 meses después de
          acabar el Bootcamp
        </h4>
      </div>
      <div className="card-bullet">
        <TimelapseIcon
          sx={{ fontSize: 50, color: "#dd0000", marginBottom: "2vh" }}
        />
        <h4 style={{ color: "black", textAlign: "center" }}>
          Método learning by Doing: trabajarás en proyectos reales con timings
          reales
        </h4>
      </div>
      <div className="card-bullet">
        <AccountTreeIcon
          sx={{ fontSize: 50, color: "#dd0000", marginBottom: "2vh" }}
        />
        <h4 style={{ color: "black", textAlign: "center" }}>
          Desarrollo de soft skills y metodologías de trabajo ágiles
        </h4>
      </div>
      <div className="card-bullet">
        <ComputerIcon
          sx={{ fontSize: 50, color: "#dd0000", marginBottom: "2vh" }}
        />
        <h4 style={{ color: "black", textAlign: "center" }}>
          Proyecto final de aplicación full stack para empresas reales
        </h4>
      </div>
    </div>
  );
}
