export default function Precurso() {
  return (
    <div id="precurso" style={{ maxWidth: "150%!important" }}>
      <h2 id="precurso-h2">No hace falta saber programar</h2>
      <p id="precurso-h4" className="explanation">
        Nuestra formación incluye un curso preparatorio para que todos los
        alumnos partan con el mismo nivel.
      </p>
    </div>
  );
}
